import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './section.module.scss';

import { Container, Row } from 'react-grid-system';
let cx = classNames.bind(styles);

function Section(props) {
  const sectionClassName = cx({
    section: true,
    sectionPrimary: props.variant === 'primary',
    sectionSecondary: props.variant === 'secondary',
    sectionTertiary: props.variant === 'tertiary'
  });

  const containerClassName = cx({
    container: true,
    containerBordered: props.hasBorder,
    containerSmall: props.size === 'small',
    containerFull: !!props.fullBleed
  });

  return (
    <section className={sectionClassName}>
      <Container
        fluid={!!props.fullBleed}
        className={containerClassName}
      >
        <Row
          justify={props.isCentered ? "center" : "start"}
        >
          {props.children}
        </Row>
      </Container>
    </section>
  );
}

Section.propTypes = {
  hasBorder: PropTypes.bool,
  fullBleed: PropTypes.bool,
  isCentered: PropTypes.bool,
  size:PropTypes.string,
  variant: PropTypes.string
};

Section.defaultProps = {
  isCentered: false
}

export default Section;
