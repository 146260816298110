import React from "react";
import {graphql} from "gatsby";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import {Col} from 'react-grid-system';
import Layout from "./";
import Section from "../components/Section";

import { setConfiguration } from 'react-grid-system';

import "../sass/index.scss";

setConfiguration({gutterWidth: 32});

const SpeakerPage = (props) => {
  const data = props.data.contentfulPerson;

  console.log(data)

  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/iex1rlv.css" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous" />
      </Helmet>
      <Section>
        <Col md={3}>
          {data.image && (
            <img src={data.image.file.url} alt={`${data.name} ${data.lastName}`}/>
          )}
        </Col>
        <Col md={9}>
          {(data.firstName || data.lastName) && (
            <h1>{data.firstName} {data.lastName}</h1>
          )}
          {data.jobTitle && (
            <h3>{data.jobTitle}{data.company ? `, ${data.company}`: ""}</h3>
          )}

          {data.personBiography && (
            <div dangerouslySetInnerHTML={{
              __html: data.personBiography.childMarkdownRemark.html
            }}
            />
          )}
        </Col>
      </Section>
    </Layout>
  )
};

SpeakerPage.propTypes = {
  data: PropTypes.object
};

export default SpeakerPage;

export const speakerPageQuery = graphql`
    query($id: String!) {
        contentfulPerson(id: {eq: $id}) {
            id,
            firstName,
            lastName,
            personBiography {
                childMarkdownRemark {
                    html
                }
            },
            image {
                file {
                    url
                }
            },
            company,
            jobTitle,
            personRole,
            linkedIn
        }
    }
`;
